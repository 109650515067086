<template>
  <div class="border rounded shadow-sm bg-white">
    <div
      class="lead bg-secondary fw-medium mb-4 p-3 text-center rounded position-relative"
      style="
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      "
    >
      <button
        class="btn btn-link"
        :style="{
          position: 'absolute',
          top: '0px',
          left: '10px',
          height: '100%',
        }"
        @click="$emit('close')"
      >
        <i class="fas fa-arrow-left" />
      </button>
      {{ user ? "Edit" : "Create new" }} user
    </div>
    <div class="p-3 px-md-5 pb-md-5">
      <div class="row g-5">
        <div class="col-12 col-lg-6">
          <h5 class="mb-3 py-2">Account Details</h5>
          <div class="mb-4">
            <label for="email" class="form-label small">
              Email address<span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              id="email"
              v-model="models.email"
              :class="{ 'is-invalid': errors.email }"
            />
          </div>
          <div class="mb-4">
            <label for="name" class="form-label small">
              Name<span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control text-uppercase"
              id="name"
              v-model="models.name"
              @input="models.name = models.name.toUpperCase()"
              :class="{ 'is-invalid': errors.name }"
            />
          </div>

          <h5 class="mb-3 py-2">Account Type</h5>
          <div class="btn-group" role="group">
            <input
              type="radio"
              class="btn-check"
              name="btnType"
              id="btnTypeParent"
              :value="null"
              v-model="accountType"
            />
            <label class="btn btn-outline-primary" for="btnTypeParent">
              Parent
            </label>

            <input
              type="radio"
              class="btn-check"
              name="btnType"
              id="btnTypeStudent"
              value="isStudent"
              v-model="accountType"
            />
            <label class="btn btn-outline-primary" for="btnTypeStudent">
              Student
            </label>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <h5 class="mb-3 py-2">Contact Details</h5>
          <div class="mb-4">
            <label for="phone" class="form-label small">
              Phone<span class="text-danger">*</span>
            </label>
            <input
              type="tel"
              class="form-control"
              id="phone"
              v-model="models.phone"
              @input="models.phone = models.phone.replace(/[^\d,]/g, '')"
              :class="{ 'is-invalid': errors.phone }"
            />
          </div>
          <div class="mb-4">
            <label for="address1" class="form-label small">Address</label>
            <input
              type="text"
              class="form-control mb-3"
              id="address1"
              v-model="models.address1"
              :class="{ 'is-invalid': errors.address1 }"
            />
            <input
              type="text"
              class="form-control"
              id="address2"
              v-model="models.address2"
              :class="{ 'is-invalid': errors.address2 }"
            />
          </div>
          <div class="row g-3">
            <div class="mb-4 col-12 col-sm-6">
              <label for="postcode" class="form-label small">Postcode</label>
              <input
                type="text"
                class="form-control"
                id="postcode"
                v-model="models.postcode"
                @input="
                  models.postcode = models.postcode.replace(/[^\d,]/g, '')
                "
                :class="{ 'is-invalid': errors.postcode }"
              />
            </div>
            <div class="mb-4 col-12 col-sm-6">
              <label for="phone" class="form-label small">City</label>
              <input
                type="text"
                class="form-control"
                id="city"
                v-model="models.city"
                :class="{ 'is-invalid': errors.city }"
              />
            </div>
          </div>
          <div class="row g-3">
            <div class="col-12 col-sm-6">
              <label for="state" class="form-label small">State</label>
              <select
                class="form-select"
                v-model="models.state"
                :class="{ 'is-invalid': errors.state }"
              >
                <option :value="null">-- Select state</option>
                <option :value="state" v-for="state in states" :key="state">
                  {{ state }}
                </option>
              </select>
            </div>
            <div class="col-12 col-sm-6">
              <label for="country" class="form-label small">Country</label>
              <input
                type="text"
                class="form-control"
                id="country"
                readonly
                v-model="models.country"
                :class="{ 'is-invalid': errors.country }"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-5 mb-4" />
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn btn-light shadow" @click="$emit('close')">
          Cancel
        </button>
        <button class="btn btn-success" @click="save()">
          {{ user ? "Update" : "Create" }} user
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import States from "@/utils/states";
import Swal from "sweetalert2";

export default {
  props: ["user"],
  components: { LoadingSpinner },
  data() {
    return {
      isLoading: false,
      states: States,
      accountType: null,
      models: {
        email: null,
        name: null,
        phone: null,
        address1: null,
        address2: null,
        postcode: null,
        city: null,
        state: null,
        country: "Malaysia",
      },
      errors: {
        email: false,
        name: false,
        phone: false,
        address1: false,
        address2: false,
        postcode: false,
        city: false,
        state: false,
        country: false,
      },
    };
  },
  methods: {
    save() {
      let body = { ...this.models };

      body.username = body.email;

      if (this.accountType) {
        body[this.accountType] = true;
      }

      for (const key in body) {
        if (Object.hasOwnProperty.call(body, key)) {
          const element = body[key];

          if (key == "name") {
            this.errors[key] = !element || element.length < 1;
          } else if (key == "email") {
            this.errors[key] = !this.Helper.validateEmail(element);
          } else if (key == "phone") {
            this.errors[key] = !this.Helper.validateTel(element);
          } else if (key == "postcode" && element && element.length > 0) {
            this.errors[key] = !this.Helper.validatePostcode(element);
          } else {
            this.errors[key] = false;
          }
        }
      }

      let invalidForm = Object.values(this.errors).some(
        (error) => error == true
      );

      if (invalidForm) {
        return new Swal("", "Invalid form submitted", "error");
      } else {
        this.isLoading = true;

        let submitMethod = "post";

        let url = "users";

        if (!this.user) {
          body.password = this.Helper.defaultPassword;
          body.confirmed = true;
        } else {
          submitMethod = "put";

          url += `/${this.user._id}`;
        }

        this.API[submitMethod](url, body)
          .then((retVal) => {
            let title = "User created successfully";

            let html = `<p>Copy password below and share it with the user</p>
							<h3 class="mb-3">
								<span id="generatedPassword" class="badge bg-teal-dark fw-normal px-4">
									${body.password}
								</span>
							</h3>
							<p class="mb-0">Please remind user to change their password upon first login</p>
						`;

            if (this.user) {
              title = "User updated";
              html = "";
            }

            return new Swal(title, html, "success").then(() => {
              this.$emit("close", retVal.data, this.user ? "edit" : null);
            });
          })
          .catch((error) => {
            return new Swal(
              "",
              error.response.data.message[0].messages[0].message.replace(
                "Username",
                "Email address"
              ),
              "error"
            );
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
  mounted() {
    if (this.user) {
      for (const key in this.models) {
        if (Object.hasOwnProperty.call(this.models, key)) {
          this.models[key] =
            key == "state" && this.user.state
              ? this.user.state.replace("_", " ")
              : this.user[key];
        }
      }
    }
  },
};
</script>